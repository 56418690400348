/**
 * This is a placeholder element that carries data properties, it has no functionality, just for
 * semantic mark-up.
 *
 * @todo document data attributes
 */
class ProductOptionGroup extends HTMLElement {
  readonly dataset!: {
    name: string;
    position: string;
    selectedOption: string;
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'product-option-group': ProductOptionGroup;
  }
}

if (!customElements.get('product-option-group')) {
  customElements.define('product-option-group', ProductOptionGroup);
}

/**
 * This is a placeholder element that just carries data properties, it has no functionality, just
 * for semantic mark-up.
 *
 * @todo document data attributes
 */
class ProductOption extends HTMLElement {
  readonly dataset!: {
    value: string;
  };
}

declare global {
  interface HTMLElementTagNameMap {
    'product-option': ProductOption;
  }
}

if (!customElements.get('product-option')) {
  customElements.define('product-option', ProductOption);
}
